.container {
		width: 100%;
}
	
@media (min-width: 640px) {
	
		.container {
				max-width: 640px;
		}
}
	
@media (min-width: 768px) {
	
		.container {
				max-width: 768px;
		}
}
	
@media (min-width: 1024px) {
	
		.container {
				max-width: 1024px;
		}
}
	
@media (min-width: 1280px) {
	
		.container {
				max-width: 1280px;
		}
}
	
@media (min-width: 1536px) {
	
		.container {
				max-width: 1536px;
		}
}
	
	.dialog-header {
		display: flex;
		justify-content: space-between;
}
	.dialog-mask {
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		display: flex;
		justify-content: center;
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 2.5rem;
		padding-bottom: 5rem;
		background-color: #0005;
}
	.dialog-body {
		display: flex;
		height: -moz-max-content;
		height: max-content;
		flex-direction: column;
		gap: 1.25rem;
		padding: 1.25rem;
}
.custom-even-row-style > div:nth-child(even) {
	background: rgba(250, 250, 250, 0.75);
}